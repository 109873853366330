<template>
 <div id="Collaborators" class="bg-white min-h-screen h-full w-full sm:max-w-xl md:max-w-full lg:max-w-screen-xl mx-auto flex items-center justify-center p-5">
        <div class="px-4 py-16 mx-auto w-full">
        <div class="mx-auto mb-10 lg:max-w-xl sm:text-center">
            <h4 class="text-5xl text-center font-extrabold font-josefin">
               Collaboratori 
            </h4>
            <p class="text-base text-gray-700 md:text-lg">
                Lo Studio legale Lelli mette a disposizione differenti figure professionali per differenti necessità
            </p>
        </div>
        <div class="grid gap-10 mx-auto md:grid-cols-3 lg:grid-cols-5 lg:max-w-screen-lg">
            <div class="md:col-start-2 lg:col-start-3">
                <div class="relative pb-56 mb-4 rounded shadow lg:pb-64 border border-gold-500">
                    <img class="absolute object-cover w-full h-full rounded object-cover" src="/img/faces/claudio_lelli.webp" alt="Person" />
                </div>
                <div class="flex flex-col text-center">
                    <div class="flex items-center gap-4 text-gold-500 mx-auto">
                        <hr class="w-14 border-gold-500">
                        ⌘
                        <hr class="w-14 border-gold-500">
                    </div>
                    <p class="text-xl font-bold">Claudio Lelli</p>
                    <p class="mb-5 text-md text-gray-800">Dottore Commercialista</p>
                    <div class="flex items-center space-x-3 justify-center">
                        <a href="https://www.linkedin.com/in/claudio-lelli-a4a99b7b/" target="_blank" class="text-gray-600 transition-colors duration-300 hover:text-gold-500 duration-300" title="Linkedin">
                            <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="fill-current w-5"><path d="M17.5,8.999a5.41868,5.41868,0,0,0-2.56543.64453A.99918.99918,0,0,0,14,8.999H10a.99943.99943,0,0,0-1,1v12a.99942.99942,0,0,0,1,1h4a.99942.99942,0,0,0,1-1v-5.5a1,1,0,1,1,2,0v5.5a.99942.99942,0,0,0,1,1h4a.99942.99942,0,0,0,1-1v-7.5A5.50685,5.50685,0,0,0,17.5,8.999Zm3.5,12H19v-4.5a3,3,0,1,0-6,0v4.5H11v-10h2v.70313a1.00048,1.00048,0,0,0,1.78125.625A3.48258,3.48258,0,0,1,21,14.499Zm-14-12H3a.99943.99943,0,0,0-1,1v12a.99942.99942,0,0,0,1,1H7a.99942.99942,0,0,0,1-1v-12A.99943.99943,0,0,0,7,8.999Zm-1,12H4v-10H6ZM5.01465,1.542A3.23283,3.23283,0,1,0,4.958,7.999h.02832a3.23341,3.23341,0,1,0,.02832-6.457ZM4.98633,5.999H4.958A1.22193,1.22193,0,0,1,3.58887,4.77051c0-.7461.55957-1.22852,1.42578-1.22852A1.2335,1.2335,0,0,1,6.41113,4.77051C6.41113,5.5166,5.85156,5.999,4.98633,5.999Z"/></svg>
                        </a>
                        <a href="https://www.facebook.com/claudio.lelli.1042" target="_blank" class="text-gray-600 transition-colors duration-300 hover:text-gold-500 duration-300" title="Facebook">
                            <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="fill-current w-5"><path d="M15.12,5.32H17V2.14A26.11,26.11,0,0,0,14.26,2C11.54,2,9.68,3.66,9.68,6.7V9.32H6.61v3.56H9.68V22h3.68V12.88h3.06l.46-3.56H13.36V7.05C13.36,6,13.64,5.32,15.12,5.32Z"/></svg>
                        </a>
                        <a href="//studiocommercialistalelli.it/" target="_blank" class="text-gray-600 transition-colors duration-300 hover:text-gold-500 duration-300" title="Web Site">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fill-current w-5"><path d="M21.41,8.64s0,0,0-.05a10,10,0,0,0-18.78,0s0,0,0,.05a9.86,9.86,0,0,0,0,6.72s0,0,0,.05a10,10,0,0,0,18.78,0s0,0,0-.05a9.86,9.86,0,0,0,0-6.72ZM4.26,14a7.82,7.82,0,0,1,0-4H6.12a16.73,16.73,0,0,0,0,4Zm.82,2h1.4a12.15,12.15,0,0,0,1,2.57A8,8,0,0,1,5.08,16Zm1.4-8H5.08A8,8,0,0,1,7.45,5.43,12.15,12.15,0,0,0,6.48,8ZM11,19.7A6.34,6.34,0,0,1,8.57,16H11ZM11,14H8.14a14.36,14.36,0,0,1,0-4H11Zm0-6H8.57A6.34,6.34,0,0,1,11,4.3Zm7.92,0h-1.4a12.15,12.15,0,0,0-1-2.57A8,8,0,0,1,18.92,8ZM13,4.3A6.34,6.34,0,0,1,15.43,8H13Zm0,15.4V16h2.43A6.34,6.34,0,0,1,13,19.7ZM15.86,14H13V10h2.86a14.36,14.36,0,0,1,0,4Zm.69,4.57a12.15,12.15,0,0,0,1-2.57h1.4A8,8,0,0,1,16.55,18.57ZM19.74,14H17.88A16.16,16.16,0,0,0,18,12a16.28,16.28,0,0,0-.12-2h1.86a7.82,7.82,0,0,1,0,4Z"/></svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    
</template>

<template>
  <Navbar/>
  <Header />
  <About />
  <hr class="border-gray-100">
  <WorkWithMe />
  <Services />
  <Collaborators />
  <div class="flex items-center justify-center gap-4 text-gold-500 mx-auto mt-4 text-5xl">
      <hr class="w-full border-gold-500">
      ⌘
      <hr class="w-full border-gold-500">
  </div>
  <Contacts/>
  <Footer />
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';

import Header from './sections/Header.vue';
import About from './sections/About.vue';
import Services from './sections/Services.vue';
import Collaborators from './sections/Collaborators.vue';
import Contacts from './sections/Contacts.vue';
import WorkWithMe from './sections/WorkWithMe.vue';





export default {
  name: 'App',
  components: 
  {
    Navbar,
    Header,
    About,
    Services,
    Collaborators,
    Contacts,
    Footer,
    WorkWithMe
  }
}
</script>

<style src="./index.css"></style>


<template>
    <div id="Services" class="min-h-screen bg-gold-500 bg-opacity-20 py-20 xl:py-1">
        <div class="h-full sm:max-w-xl md:max-w-full lg:max-w-screen-xl p-4 mx-auto px-4 sm:px-6 lg:px-8 relative py-26 lg:mt-20 bg-transparent">
            <div class="relative">
                <div class="lg:grid grid-cols-1 xl:grid-flow-row-dense xl:grid-cols-2 lg:gap-8 lg:items-center">
                    <div class="xl:col-start-2 xl:max-w-2xl ml-auto px-6">
                        <h4 class="mt-2 text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl md:text-4xl sm:leading-9 text-center">
                            Una soluzione per ogni esigenza
                        </h4>
                        <div class="flex items-center justify-center gap-4 text-gold-500 mx-auto mt-4">
                            <hr class="w-32 border-gold-500">
                            ⌘
                            <hr class="w-32 border-gold-500">
                        </div>
                        <p class="mt-4 text-2xl leading-6 text-center">
                            Lo Studio Legale Lelli offre soluzioni per ogni esigenza
                        </p>
                        <ul class="mt-8 md:grid md:grid-cols-2 gap-6">
                            <li class="mt-6 lg:mt-0">
                                <div class="flex text-gray-800">
                                    <span class="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full text-gold-500 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M20.07,6.11a9.85,9.85,0,0,0-4.3-3.36A10,10,0,0,0,2,12c0,.19,0,.38,0,.56A9.94,9.94,0,0,0,3.33,17a10,10,0,0,0,5.89,4.65h0A10.11,10.11,0,0,0,12,22a9.45,9.45,0,0,0,1.88-.18,10,10,0,0,0,8-8.41A9.46,9.46,0,0,0,22,12,9.83,9.83,0,0,0,20.07,6.11Zm-2,.77L17,9.74l-1.62.44L13,8.49V6.64l2.49-1.81A7.81,7.81,0,0,1,18.11,6.88ZM14,11.67,13.22,14H10.77L10,11.67l2-1.43ZM12,4a8,8,0,0,1,1.11.09L12,4.89l-1.11-.8A8,8,0,0,1,12,4ZM4.88,8.37l.4,1.32-1.13.79A7.88,7.88,0,0,1,4.88,8.37Zm1.37,9.17,1.38.05L8,18.92A8.32,8.32,0,0,1,6.25,17.54ZM8,15.6l-3.15-.11A7.83,7.83,0,0,1,4.07,13l2.49-1.74L8,11.88l.89,2.76Zm.86-5.53-1.56-.7-.91-3A7.93,7.93,0,0,1,8.5,4.83L11,6.64V8.49ZM13,19.93a8.08,8.08,0,0,1-2.63-.12l-.83-2.92.83-.89h3.07l.67,1Zm2.41-.7L15.87,18l1.36.07A7.83,7.83,0,0,1,15.38,19.23Zm3.46-3.12L15.76,16l-.71-1.1.89-2.76,1.51-.41,2.36,2A7.84,7.84,0,0,1,18.84,16.11Zm.05-5.83L19.4,9a7.4,7.4,0,0,1,.53,2.13Z"/></svg>
                                    </span>
                                    <span class="ml-4 text-xl leading-6 font-medium">
                                        Diritto dello Sport
                                    </span>
                                </div>
                            </li>
                            <li class="mt-6 lg:mt-0">
                                <div class="flex">
                                    <span class="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full text-gold-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M22,17H2a1,1,0,0,0,0,2H22a1,1,0,0,0,0-2Zm0,4H2a1,1,0,0,0,0,2H22a1,1,0,0,0,0-2ZM6,7A1,1,0,1,0,7,8,1,1,0,0,0,6,7ZM20,1H4A3,3,0,0,0,1,4v8a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V4A3,3,0,0,0,20,1Zm1,11a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V4A1,1,0,0,1,4,3H20a1,1,0,0,1,1,1ZM12,5a3,3,0,1,0,3,3A3,3,0,0,0,12,5Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,9Zm6-2a1,1,0,1,0,1,1A1,1,0,0,0,18,7Z"/></svg>
                                    </span>
                                    <span class="ml-4 text-xl leading-6 font-medium text-gray-800">
                                        Diritto Commerciale
                                    </span>
                                </div>
                            </li>
                            <li class="mt-6 lg:mt-0">
                                <div class="flex">
                                    <span class="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full text-gold-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M14,8h1a1,1,0,0,0,0-2H14a1,1,0,0,0,0,2Zm0,4h1a1,1,0,0,0,0-2H14a1,1,0,0,0,0,2ZM9,8h1a1,1,0,0,0,0-2H9A1,1,0,0,0,9,8Zm0,4h1a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Zm12,8H20V3a1,1,0,0,0-1-1H5A1,1,0,0,0,4,3V20H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm-8,0H11V16h2Zm5,0H15V15a1,1,0,0,0-1-1H10a1,1,0,0,0-1,1v5H6V4H18Z"/></svg>
                                    </span>
                                    <span class="ml-4 text-xl leading-6 font-medium text-gray-800">
                                        Diritto Immobiliare
                                    </span>
                                </div>
                            </li>
                            <li class="mt-6 lg:mt-0">
                                <div class="flex">
                                    <span class="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full text-gold-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M6.62,13.08a.9.9,0,0,0-.54.54,1,1,0,0,0,1.3,1.3,1.15,1.15,0,0,0,.33-.21,1.15,1.15,0,0,0,.21-.33A.84.84,0,0,0,8,14a1.05,1.05,0,0,0-.29-.71A1,1,0,0,0,6.62,13.08Zm13.14-4L18.4,5.05a3,3,0,0,0-2.84-2H8.44A3,3,0,0,0,5.6,5.05L4.24,9.11A3,3,0,0,0,2,12v4a3,3,0,0,0,2,2.82V20a1,1,0,0,0,2,0V19H18v1a1,1,0,0,0,2,0V18.82A3,3,0,0,0,22,16V12A3,3,0,0,0,19.76,9.11ZM7.49,5.68A1,1,0,0,1,8.44,5h7.12a1,1,0,0,1,1,.68L17.61,9H6.39ZM20,16a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H19a1,1,0,0,1,1,1Zm-3.38-2.92a.9.9,0,0,0-.54.54,1,1,0,0,0,1.3,1.3.9.9,0,0,0,.54-.54A.84.84,0,0,0,18,14a1.05,1.05,0,0,0-.29-.71A1,1,0,0,0,16.62,13.08ZM13,13H11a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z"/></svg>
                                    </span>
                                    <span class="ml-4 text-xl leading-6 font-medium text-gray-800">
                                        Diritto di Circolazione Stradale
                                    </span>
                                </div>
                            </li>
                        </ul>
                        <div class="flex items-center justify-center w-full my-12">
                            <a href="#Contacts" class="bg-black px-4 py-2 rounded text-white hover:text-gold-500 text-lg duration-300">
                                Contatta lo studio
                            </a>
                        </div>
                    </div>
                    <div class="mt-10 xl:-mx-4 relative relative-20 xl:mt-0 xl:col-start-1 p-6">
                        <div class="relative space-y-4">
                            <div class="flex items-end justify-center xl:justify-start space-x-4">
                                <div class="rounded-lg shadow-lg w-full xl:w-60 relative">
                                    <img class="hidden xl:block rounded-lg w-full" width="200" src="/img/services/diritto_sport_xl.jpg" alt="Diritto dello sport"/>
                                    <img class="xl:hidden rounded-lg w-full" width="200" src="/img/services/diritto_sport.jpg" alt="Diritto dello sport"/>
                                    
                                    <div class="absolute rounded-lg top-0 bottom-0 right-0 left-0 bg-black bg-opacity-60 flex items-center justify-center">
                                        <h3 class="text-xl md:text-4xl xl:text-5xl font-josefin flex flex-wrap text-white text-center">
                                            <b>
                                                Diritto dello Sport
                                            </b>
                                        </h3>
                                    </div>
                                </div>
                                <div class="rounded-lg shadow-lg w-full xl:w-72 relative">
                                    <img class="rounded-lg w-full" width="200" src="/img/services/diritto_commerciale.jpg" alt=" Diritto Commerciale"/>
                                    <div class="absolute rounded-lg top-0 bottom-0 right-0 left-0 bg-black bg-opacity-60 flex items-center justify-center">
                                        <h3 class="text-xl md:text-4xl font-josefin flex flex-wrap text-white text-center">
                                            <b>
                                                Diritto Commerciale
                                            </b>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-start justify-center xl:justify-start space-x-4 xl:ml-12">
                                <div class="rounded-lg shadow-lg w-full xl:w-52 relative">
                                    <img class="rounded-lg w-full" width="200" src="/img/services/diritto_immobiliare.jpg" alt="Diritto Immobiliare"/>
                                    <div class="absolute rounded-lg top-0 bottom-0 right-0 left-0 bg-black bg-opacity-60 flex items-center justify-center">
                                        <h3 class="text-xl md:text-4xl font-josefin flex flex-wrap text-white text-center">
                                            <b>
                                                Diritto Immobiliare
                                            </b>
                                        </h3>
                                    </div>
                                </div>
                                <div class="rounded-lg shadow-lg w-full xl:w-60 relative">
                                    <img class="hidden xl:block rounded-lg w-full" width="200" src="/img/services/diritto_stradale_xl.jpg" alt="Diritto di Circolazione Stradale"/>
                                    <img class="xl:hidden rounded-lg w-full" width="200" src="/img/services/diritto_stradale.jpg" alt="Diritto di Circolazione Stradale"/>
                                    
                                    <div class="absolute rounded-lg top-0 bottom-0 right-0 left-0 bg-black bg-opacity-60 xl:bg-opacity-30 flex items-center justify-center">
                                        <h3 class="text-xl md:text-4xl xl:text-5xl font-josefin flex flex-wrap text-white text-center">
                                            <b>
                                                Diritto di Circolazione Stradale
                                            </b>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
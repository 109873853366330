<template>
    <div id="About" class="bg-white min-h-screen h-full w-full sm:max-w-xl md:max-w-full lg:max-w-screen-xl mx-auto flex items-center justify-center p-5">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-14 lg:gap-32">
            <div class="h-full w-full rounded-2xl p-1">
                <div class="w-2/3 lg:w-9/12 xl:w-8/12 mx-auto my-auto rounded-2xl border-4 border-gold-500 relative group">
                    <img src="/img/faces/massimiliano_lelli.jpg" 
                        class="w-full object-cover rounded-xl" alt="">
                    <div class="absolute top-0 right-0 bottom-0 left-0 bg-black rounded-2xl opacity-0 bg-opacity-0 group-hover:bg-opacity-50 group-hover:opacity-100 duration-300 flex flex-col items-center justify-center gap-6">
                        <h3 class="text-xl lg:text-2xl text-white font-josefin text-center">
                            <b>
                                Vieni a trovarmi sui social
                            </b> 
                        </h3>
                        <div class="flex items-center gap-4 text-white">
                            <hr class="w-12">
                            ⌘
                            <hr class="w-12">
                        </div>
                        <div class="flex items-center justify-center gap-5">
                            <a 
                                href="//www.facebook.com/massimiliano.lelli.391" 
                                class="text-white hover:text-gold-500 duration-500"
                                target="_blank"
                                title="Facebook">
                                <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="fill-current w-6"><path d="M15.12,5.32H17V2.14A26.11,26.11,0,0,0,14.26,2C11.54,2,9.68,3.66,9.68,6.7V9.32H6.61v3.56H9.68V22h3.68V12.88h3.06l.46-3.56H13.36V7.05C13.36,6,13.64,5.32,15.12,5.32Z"/></svg>
                            </a>
                            <a 
                                href="//www.linkedin.com/in/massimiliano-lelli-5104851b8/" 
                                class="text-white hover:text-gold-500 duration-500"
                                target="_blank"
                                title="LinkedIn">
                                <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="fill-current w-6"><path d="M17.5,8.999a5.41868,5.41868,0,0,0-2.56543.64453A.99918.99918,0,0,0,14,8.999H10a.99943.99943,0,0,0-1,1v12a.99942.99942,0,0,0,1,1h4a.99942.99942,0,0,0,1-1v-5.5a1,1,0,1,1,2,0v5.5a.99942.99942,0,0,0,1,1h4a.99942.99942,0,0,0,1-1v-7.5A5.50685,5.50685,0,0,0,17.5,8.999Zm3.5,12H19v-4.5a3,3,0,1,0-6,0v4.5H11v-10h2v.70313a1.00048,1.00048,0,0,0,1.78125.625A3.48258,3.48258,0,0,1,21,14.499Zm-14-12H3a.99943.99943,0,0,0-1,1v12a.99942.99942,0,0,0,1,1H7a.99942.99942,0,0,0,1-1v-12A.99943.99943,0,0,0,7,8.999Zm-1,12H4v-10H6ZM5.01465,1.542A3.23283,3.23283,0,1,0,4.958,7.999h.02832a3.23341,3.23341,0,1,0,.02832-6.457ZM4.98633,5.999H4.958A1.22193,1.22193,0,0,1,3.58887,4.77051c0-.7461.55957-1.22852,1.42578-1.22852A1.2335,1.2335,0,0,1,6.41113,4.77051C6.41113,5.5166,5.85156,5.999,4.98633,5.999Z"/></svg>
                            </a>
                            <a 
                                href="//omnes.one/massimiliano-lelli" 
                                class="text-white hover:text-gold-500 duration-500"
                                target="_blank"
                                title="One">
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.000000 100.000000" class="fill-current w-6"><g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)" stroke="none"><path d="M348 961 c-340 -109 -445 -552 -192 -805 259 -258 708 -144 809 207 23 79 21 205 -3 284 -17 52 -76 163 -87 163 -2 0 11 -28 28 -62 30 -59 32 -70 32 -168 0 -95 -3 -110 -28 -163 -16 -32 -51 -80 -77 -106 -222 -222 -597 -101 -652 211 -28 158 60 326 210 400 58 29 79 34 162 37 120 5 85 19 -50 19 -66 1 -113 -5 -152 -17z"/><path d="M469 877 c-91 -34 -145 -85 -185 -171 -23 -51 -26 -67 -22 -135 2 -42 10 -85 16 -96 11 -18 12 -17 6 7 -10 43 15 126 49 165 62 71 171 96 251 58 71 -34 126 -119 126 -196 0 -99 -84 -192 -188 -208 l-47 -7 50 -8 c94 -13 222 40 274 113 61 85 78 197 45 294 -21 61 -84 132 -148 164 -62 31 -171 41 -227 20z"/></g></svg>
                            </a>


                        </div>
                    </div>
                    
                </div>
                <div class="flex items-center justify-center gap-4 text-gold-500 mx-auto mt-4">
                    <hr class="w-32 border-gold-500">
                    ⌘
                    <hr class="w-32 border-gold-500">
                </div>
                <h4 class="text-5xl text-center font-josefin">
                    Massimiliano Lelli
                </h4>
            </div>
            <div class="">
                <p class="text-lg leading-loose font-josefin px-6">
                    <b>
                        L’Avv. Lelli Massimiliano, fondatore dell'omonimo Studio Legale, si è laureato presso 
                        l'Università degli Studi di Macerata, una fra le più antiche università d'Europa. 
                        Dal 1993 è iscritto all'albo degli avvocati di Macerata.
                        Con oltre 25 anni di esperienza, mette a disposizione, in attività giudiziali e stragiudiziali, 
                        la propria competenza per far sì che gli assistiti ricevano sempre il miglior servizio possibile. <br>
                        Efficienza, trasparenza e dedizione sono gli aggettivi che meglio caratterizzano lo 
                        Studio Legale Lelli di Civitanova Marche, dove ogni servizio è stato ideato per venire 
                        incontro al cliente in ogni sua esigenza, ponendolo sempre al primo posto.</b>
                </p>

            </div>
        </div>
    </div>
</template>

<style scoped>
* > .intro-to-l:nth-child(1) {
    z-index: 49;
    opacity: 0;
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out 0.33333s;
            animation: 0.4s intro-x-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }

  * > .intro-to-r:nth-child(1) {
    z-index: 49;
    opacity: 0;
    position: relative;
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out 0.33333s;
            animation: 0.4s intro-x-animation ease-in-out 0.33333s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }

  @-webkit-keyframes intro-x-animation {
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  @keyframes intro-x-animation {
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
</style>
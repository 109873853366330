<template>
    <div id="Contacts" class="bg-white min-h-screen h-full w-full sm:max-w-xl md:max-w-full lg:max-w-screen-xl mx-auto flex items-center justify-center p-5">
        <section class="text-gray-600 body-font relative w-full">
            <div class=" px-5 py-24 mx-auto flex flex-col lg:flex-row sm:flex-nowrap flex-wrap w-full lg:space-x-10">
                <div class="order-2 lg:order-1 w-full lg:w-2/3 bg-gray-300 rounded-lg overflow-hidden flex items-end justify-start relative">
                    <iframe class="w-full h-full min-h-xs" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2903.7088723557667!2d13.724673215684497!3d43.299418079134945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13327282227348a9%3A0x1bbda4ae098a5ef3!2sLelli%20Avv.%20Massimiliano!5e0!3m2!1sit!2sit!4v1635970797008!5m2!1sit!2sit" width="100%" height="100%" frameborder="0"></iframe>
                </div>
                <div class="order-1 lg:order-2 w-full lg:w-1/3 bg-white text-center lg:text-left flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                    <h2 class="text-5xl font-josefin">
                        Contatti
                    </h2>
                    <p class="leading-relaxed text-xl mb-5 text-gray-600">
                        Contatta lo Studio Legale Lelli per una consulenza gratuita
                    </p>
                    <div class="my-4">
                        <h2 class="title-font font-semibold text-gray-900 tracking-widest text-lg xl:text-xl uppercase">EMAIL</h2>
                        <a href="mailto:avv.maxlelli@tiscali.it" class="text-gold-500 leading-relaxed text-xl xl:text-2xl hover:underline">avv.maxlelli@tiscali.it</a>
                    </div>
                    <div class="my-4">
                        <h2 class="title-font font-semibold text-gray-900 tracking-widest text-lg xl:text-xl uppercase">TELEFONO</h2>
                        <a href="tel:0733 811734" class="text-gold-500 leading-relaxed text-xl xl:text-2xl hover:underline">0733 811734</a>
                    </div>
                    <!-- <div class="my-4">
                        <h2 class="title-font font-semibold text-gray-900 tracking-widest text-lg xl:text-xl uppercase">FAX</h2>
                        <a class="text-gold-500 leading-relaxed text-xl xl:text-2xl">0733 811734</a>
                    </div> -->
                    <div class="my-4">
                        <h2 class="title-font font-semibold text-gray-900 tracking-widest text-lg xl:text-xl uppercase">INDIRIZZO</h2>
                        <a href="//www.google.com/maps/dir//Lelli+Avv.+Massimiliano+Via+Crimea,+29+62012+Civitanova+Marche+MC/@43.299477,13.72686,17z/data=!4m5!4m4!1m0!1m2!1m1!1s0x13327282227348a9:0x1bbda4ae098a5ef3" target="_blank" class="text-gold-500 leading-relaxed text-xl xl:text-2xl hover:underline">Via Crimea 29, Civitanova Marche (MC), 62012</a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

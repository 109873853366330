<template>
    <div id="Home" class="flex w-full min-h-screen h-full bg-no-repeat bg-cover relative" style="background-image: url('/img/backgrounds/main.webp')">
        <div class="absolute top-0 right-0 left-0 bottom-0 bg-black bg-opacity-50 text-white z-10 flex flex-col gap-5 items-center justify-center">
            <div class="flex flex-col gap-5 items-center justify-center mx-auto mt-auto">
                <h3 class="font-josefin uppercase text-lg md:text-2xl lg:text-3xl text-center duration-300">
                    Avvocato
                </h3>
                <h1 class="font-josefin  tracking-widest uppercase text-4xl md:text-6xl lg:text-9xl text-center duration-300 flex flex-wrap">
                    Massimiliano Lelli
                </h1>
                <div class="flex items-center gap-4">
                    <hr class="w-32">
                    ⌘
                    <hr class="w-32">
                </div>
                <h3 class="font-josefin italic text-lg md:text-2xl lg:text-3xl text-center duration-300">
                    Il giusto modo di farsi rappresentare
                </h3>
            </div>
            <a href="#About" class="border p-2 rounded-full animate-bounce justify-self-end mb-5 mt-auto opacity-75">
                <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="text-white fill-current w-8"><path d="M17.71,11.29a1,1,0,0,0-1.42,0L13,14.59V7a1,1,0,0,0-2,0v7.59l-3.29-3.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l5-5A1,1,0,0,0,17.71,11.29Z"/></svg>
            </a>
        </div>
    </div>
</template>
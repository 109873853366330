<template>
    <div class="flex flex-col space-y-5 items-center justify-center text-center">
        <slot/>
        <h1 class="text-2xl lg:text-3xl font-extrabold">
            {{ title }}
        </h1>
        <p class="text-lg leading-loose font-josefin px-1">
           {{ body }}
        </p>
    </div>
</template>
<script>
export default {
    props:
    {
        title:
        {
            required: true,
            type: String
        },
        body:
        {
            required: true,
            type: String
        }
    }
}
</script>
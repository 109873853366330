<template>
    <footer class="text-gray-600 body-font bg-black">
        <div class="container px-5 py-8 mx-auto flex items-center md:flex-row  flex-col">
             <p class="text-lg text-gray-300 sm:py-2 sm:mt-0 mt-4 flex items-center gap-1">
                &copy; {{ new Date().getFullYear() }}
                 <a href="//studiolegalelelli.it" target="_blank" class="text-center text-white hover:underline">
                    <span class="ml-3 text-xl">Massimiliano Lelli</span>
                </a>
            </p>
            <p class="text-lg text-gray-300 sm:py-2 sm:mt-0 mt-4 flex items-center space-x-1">
                ~ Powered By
                <a href="//omnes.cloud" target="_blank" class="hover:underline ml-1">Omnes</a>
            </p>
            <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
                <a class="text-gray-200 hover:text-gold-500 duration-300" href="//www.linkedin.com/in/massimiliano-lelli-5104851b8/" tite="Linkedin" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="fill-current w-5"><path d="M15.12,5.32H17V2.14A26.11,26.11,0,0,0,14.26,2C11.54,2,9.68,3.66,9.68,6.7V9.32H6.61v3.56H9.68V22h3.68V12.88h3.06l.46-3.56H13.36V7.05C13.36,6,13.64,5.32,15.12,5.32Z"/></svg>
                </a>
                <a class="ml-3 text-gray-200 hover:text-gold-500 duration-300" href="//www.facebook.com/massimiliano.lelli.391" title="Facebook" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="fill-current w-5"><path d="M17.5,8.999a5.41868,5.41868,0,0,0-2.56543.64453A.99918.99918,0,0,0,14,8.999H10a.99943.99943,0,0,0-1,1v12a.99942.99942,0,0,0,1,1h4a.99942.99942,0,0,0,1-1v-5.5a1,1,0,1,1,2,0v5.5a.99942.99942,0,0,0,1,1h4a.99942.99942,0,0,0,1-1v-7.5A5.50685,5.50685,0,0,0,17.5,8.999Zm3.5,12H19v-4.5a3,3,0,1,0-6,0v4.5H11v-10h2v.70313a1.00048,1.00048,0,0,0,1.78125.625A3.48258,3.48258,0,0,1,21,14.499Zm-14-12H3a.99943.99943,0,0,0-1,1v12a.99942.99942,0,0,0,1,1H7a.99942.99942,0,0,0,1-1v-12A.99943.99943,0,0,0,7,8.999Zm-1,12H4v-10H6ZM5.01465,1.542A3.23283,3.23283,0,1,0,4.958,7.999h.02832a3.23341,3.23341,0,1,0,.02832-6.457ZM4.98633,5.999H4.958A1.22193,1.22193,0,0,1,3.58887,4.77051c0-.7461.55957-1.22852,1.42578-1.22852A1.2335,1.2335,0,0,1,6.41113,4.77051C6.41113,5.5166,5.85156,5.999,4.98633,5.999Z"/></svg>
                </a>
                <a class="ml-3 text-gray-200 hover:text-gold-500 duration-300" href="//omnes.one/massimiliano-lelli" title="One" target="_blank">
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.000000 100.000000" class="fill-current w-5"><g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)" stroke="none"><path d="M348 961 c-340 -109 -445 -552 -192 -805 259 -258 708 -144 809 207 23 79 21 205 -3 284 -17 52 -76 163 -87 163 -2 0 11 -28 28 -62 30 -59 32 -70 32 -168 0 -95 -3 -110 -28 -163 -16 -32 -51 -80 -77 -106 -222 -222 -597 -101 -652 211 -28 158 60 326 210 400 58 29 79 34 162 37 120 5 85 19 -50 19 -66 1 -113 -5 -152 -17z"/><path d="M469 877 c-91 -34 -145 -85 -185 -171 -23 -51 -26 -67 -22 -135 2 -42 10 -85 16 -96 11 -18 12 -17 6 7 -10 43 15 126 49 165 62 71 171 96 251 58 71 -34 126 -119 126 -196 0 -99 -84 -192 -188 -208 l-47 -7 50 -8 c94 -13 222 40 274 113 61 85 78 197 45 294 -21 61 -84 132 -148 164 -62 31 -171 41 -227 20z"/></g></svg>
                </a>
            </span>
        </div>
    </footer>
</template>
<template>
 <div id="WorkWithMe" class="bg-white  w-full sm:max-w-xl md:max-w-full lg:max-w-screen-xl mx-auto flex items-center justify-center p-5">
        <div class="px-4 py-16 mx-auto w-full">
        <div class="flex flex-col items-center justify-center space-y-4 w-full mx-auto mb-10 lg:max-w-xl sm:text-center">
            <h4 class="text-5xl text-center font-extrabold font-josefin">
               Perchè Rivolgerti a Me 
            </h4>
            <div class="flex w-full items-center space-x-4 text-gold-500 mx-auto">
                <hr class="w-1/2 border-gold-500">
                <span>⌘</span>
                <hr class="w-1/2 border-gold-500">
            </div>
            
        </div>
        <div class="grid gap-10  mx-auto grid-cols-1 md:grid-cols-2 lg:max-w-screen-lg">
            <AttributeCard 
                :title="'Trasparenza'"
                body=" L'esperienza nel campo dell'assistenza legale ha consentito di sviluppare nel tempo un approccio ideale 
                        affinchè ogni situazione venga gestita nel migliore dei modi: la costante interazione coi propri clienti 
                        permette la massima trasparenza sull'operato del professionista e le dispersioni di informazioni sono evitate 
                        quanto più possibile."
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-10 lg:w-16 fill-current text-gold-500"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
            </AttributeCard>
            <AttributeCard 
                title="Servizio di Qualit&agrave;"
                body="Tra le branche del diritto trattate dallo studio si ricordano: Diritto degli immobili, comunioni, 
                        condominio e locazioni, contrattualistica e contenzioso; Diritto delle assicurazioni con riferimento 
                        alla Responsabilità Civile da circolazione stradale e alla gestione dei sinistri, anche contenziosa; 
                        Diritto commerciale e attività di recupero crediti."
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-10 lg:w-16 fill-current text-gold-500"><path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z"/></svg>
            </AttributeCard>
            <AttributeCard 
                title="Diritto dello Sport"
                body="Una delle principali attività è il diritto dello sport, con particolare riferimento anche all'assitenza 
                giudiziaria davanti agli organi di giustizia sportiva. Nello specifico i servizi riguardano la consulenza per 
                la conclusione dei contratti di prestazione sportiva o il trasferimento, nonchè le attività di rappresentanza 
                degli atleti nei confronti delle Società sportive."
            >
                <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-10 lg:w-16 fill-current text-gold-500"><path d="M20.87,17.25l-2.71-4.68A6.9,6.9,0,0,0,19,9.25a7,7,0,0,0-14,0,6.9,6.9,0,0,0,.84,3.32L3.13,17.25A1,1,0,0,0,4,18.75l2.87,0,1.46,2.46a1,1,0,0,0,.18.22,1,1,0,0,0,.69.28h.14a1,1,0,0,0,.73-.49L12,17.9l1.93,3.35a1,1,0,0,0,.73.48h.14a1,1,0,0,0,.7-.28.87.87,0,0,0,.17-.21l1.46-2.46,2.87,0a1,1,0,0,0,.87-.5A1,1,0,0,0,20.87,17.25ZM9.19,18.78,8.3,17.29a1,1,0,0,0-.85-.49l-1.73,0,1.43-2.48a7,7,0,0,0,3.57,1.84ZM12,14.25a5,5,0,1,1,5-5A5,5,0,0,1,12,14.25Zm4.55,2.55a1,1,0,0,0-.85.49l-.89,1.49-1.52-2.65a7.06,7.06,0,0,0,3.56-1.84l1.43,2.48Z"/></svg>
            </AttributeCard>
            <AttributeCard 
                title="Esperto in Diritto Civile"
                body="I servizi di assistenza legale proposti sono stati pensati per essere quanto più completi e 
                professionali possibile. Il diritto civile rappresenta la principale area di attività dell'Avv. Massimiliano Lelli: 
                competenza, affidabilità ed esperienza sono al servizio degli assistiti dello studio legale per ogni necessità. "
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-10 lg:w-16 fill-current text-gold-500"><path d="M19,6.5H16v-1a3,3,0,0,0-3-3H11a3,3,0,0,0-3,3v1H5a3,3,0,0,0-3,3v9a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3v-9A3,3,0,0,0,19,6.5Zm-9-1a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v1H10Zm10,13a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V13.45H7V14.5a1,1,0,0,0,2,0V13.45h6V14.5a1,1,0,0,0,2,0V13.45h3Zm0-7H4V9.5a1,1,0,0,1,1-1H19a1,1,0,0,1,1,1Z"/></svg>
            </AttributeCard>
        </div>
    </div>
    </div>
    
</template>
<script>
import AttributeCard from '../components/AttributeCard.vue';
export default {
    components:{ AttributeCard }
}
</script>
